export type User = {
  identity: Identity;
  providerInfo: ProviderInfo;
  consentCompleted: ConsentCompleted;
  subscription: Subscription;
  results: Results;
  tests: Tests;
  notifications: Notifications;
  ecomm: Ecomm;
  care: Care;
  announcements: Announcement[];
  features: Features;
  experiments: Experiments;
  env: Env;
};

type Identity = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  dateJoined: string;
  isStaff: boolean;
  birthday: string;
  isProvider: boolean;
  registrationRoute: string | null;
};

type ProviderInfo = {
  isVerified: boolean;
};

type ConsentCompleted = {
  research: boolean;
};

type Subscription = {
  isMember: boolean;
};

type Results = {
  hasReadyTest: boolean;
};

type Tests = {
  totalTestCount: number;
};

type Notifications = {
  care: boolean;
};

type Ecomm = {
  canPurchaseCare: boolean;
};

type Care = {
  vaginitis: {
    hasCompletedConsult: boolean;
  };
};

type Features = {
  plan: boolean;
  carePdfDebug: boolean;
  ungatedRxUpsell: boolean;
  vaginitisUpsell: boolean;
  healthHistoryUpsell: boolean;
  providerPdfIncludePlanItems: boolean;
  bundleRetestUpsell: boolean;
};

export type Announcement = {
  id: string;
  title: string;
  message: string;
  publishedAt: string;
  viewed_at: string;
};

export type Experiments = {
  problemsModule: boolean;
  prominentOnramp: boolean;
};

type Env = {
  name: string;
  frontendUrl: string;
};

// Utils
export const toUser = (userData: any): User => {
  return {
    identity: {
      id: userData?.identity?.id,
      username: userData?.identity?.username,
      firstName: userData?.identity?.first_name,
      lastName: userData?.identity?.last_name,
      email: userData?.identity?.email,
      emailVerified: userData?.identity?.email_verified,
      dateJoined: userData?.identity?.date_joined,
      isStaff: userData?.identity?.is_staff,
      birthday: userData?.identity?.birthday,
      isProvider: userData?.identity?.is_provider,
      registrationRoute: userData?.identity?.registration_route,
    },
    providerInfo: {
      isVerified: userData?.provider_info?.is_verified,
    },
    consentCompleted: {
      research: userData?.consent_completed?.research,
    },
    subscription: {
      isMember: userData?.subscription?.is_member,
    },
    results: {
      hasReadyTest: userData?.results?.has_ready_test,
    },
    tests: {
      totalTestCount: userData?.tests?.total_test_count,
    },
    notifications: {
      care: userData?.notifications?.care,
    },
    ecomm: {
      canPurchaseCare: userData?.ecomm?.can_purchase_care,
    },
    care: {
      vaginitis: {
        hasCompletedConsult: userData?.care?.vaginitis?.has_completed_consult,
      },
    },
    announcements: userData?.announcements?.map((announcement: any) => {
      return {
        id: announcement?.id,
        title: announcement?.title,
        message: announcement?.message,
        publishedAt: announcement?.published_at,
      };
    }),
    features: {
      plan: userData?.features?.plan,
      carePdfDebug: userData?.features?.care_pdf_debug,
      ungatedRxUpsell: userData?.features?.ungated_rx_upsell,
      vaginitisUpsell: userData?.features?.vaginitis_upsell,
      healthHistoryUpsell: userData?.features?.health_history_upsell,
      bundleRetestUpsell: userData?.features?.bundle_retest_upsell,
      providerPdfIncludePlanItems:
        userData?.features?.provider_pdf_include_plan_items,
    },
    experiments: {
      problemsModule: userData?.experiments?.problems_module,
      prominentOnramp: userData?.experiments?.prominent_onramp,
    },
    env: {
      name: userData?.env?.name,
      frontendUrl: userData?.env?.frontend_url,
    },
  };
};

export type ProviderProfile = {
  provider_type: string;
  provider_type_options: [string, string][];
  provider_type_other: string;
  specialty: string;
  affiliated_practice: string;
  goals: string[];
  goals_options: [string, string][];
  goals_other: string;
  clinic_state: string;
  clinic_state_options: [string, string][];
  clinic_state_options_with_other: [string, string][];
  clinic_state_other: string;
  clinic_country: string;
  clinic_country_options: [string, string][];
  clinic_country_options_with_other: [string, string][];
  clinic_country_other: string;
  clinic_province: string;
  clinic_province_options: [string, string][];
  clinic_province_options_with_other: [string, string][];
  clinic_province_other: string;
  hdyhau: string;
  hdyhau_options: [string, string][];
  hdyhau_other: string;
  verified: boolean;
  has_completed_intake: boolean;
  is_prescribing_provider: boolean;
};
