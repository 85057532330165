import { useContext, useMemo } from "react";

// types
import { AddCartItemPayload, CartItem } from "../../types/cart";

// services
import { cartService } from "../../services/cart";
import { getConsultPurchaseTypeFromCart } from "../../utils/ecomm/utils";
import { CartContext } from "../../contexts/cartContext";
import { sumAndFormatCartItemsToPriceString } from "../../components/care/utils";
import {
  sendTreatmentCartClickedAddToCart,
  sendTreatmentCartClickedRemoveFromCart,
} from "../../utils/analytics/customEventTracking";

export const usePanelCart = () => {
  const {
    cart,
    loading: isLoading,
    setCart,
    showCart,
    setShowCart,
    latestReadyTest,
    refetchCartAndTest,
  } = useContext(CartContext);

  const baseCartAnalyticsArgs = useMemo(() => {
    const cartIsEmpty = !cart || cart.line_items.length === 0;
    const idRegex = /[A-Za-z\d]{32,100}/g;

    const pageNameExcludingIDs = window.location.pathname?.replace(
      idRegex,
      "uuid"
    );
    return {
      testHash: latestReadyTest?.hash,
      consultId: latestReadyTest?.latest_vaginitis_consult?.uid,
      careEligible: latestReadyTest?.eligible_for_care,
      aLaCareEligible: latestReadyTest?.eligible_for_a_la_care,
      eligibleTreatmentPathways:
        latestReadyTest?.eligible_treatment_pathways?.map(
          (pathway) => pathway.slug
        ),
      selectedTreatments: cart?.line_items
        .filter((item) => item.item_type === "individual-treatment")
        .map((item) => item.treatment_product.slug),
      selectedBundleTreatments: cart?.line_items
        .filter((item) => item.item_type === "bundle-treatment")
        .map((item) => item.treatment_product.slug),
      totalInCart: cart?.line_items
        ? sumAndFormatCartItemsToPriceString(cart.line_items)
        : 0,
      page: pageNameExcludingIDs,
      emptyCart: cartIsEmpty,
    };
  }, [latestReadyTest, cart]);

  const addToCart = async (items: AddCartItemPayload[]) => {
    // if not cart, create cart
    let updatedCart = cart;
    if (!cart) {
      updatedCart = await cartService.getOrCreateCart();
    }

    if (!updatedCart) {
      const errorMessage = "Error creating cart";
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    sendTreatmentCartClickedAddToCart({
      addedTreatments: items
        .filter((item) => item.item_type !== "product")
        .map((item) => item.treatment_product?.slug),
      addedBundle: items.some((item) => item.item_type === "bundle-treatment"),
      addedUpsellSkus: items
        .filter((item) => item.item_type === "product")
        .map((item) => item.ecomm_product?.sku),
      ...baseCartAnalyticsArgs,
    });

    // add items to cart
    updatedCart = await cartService.addItemsToCart(updatedCart.id, items);

    if (updatedCart) {
      setCart(updatedCart);
    }
    setShowCart(true);
  };

  const removeFromCart = async (items: CartItem[]) => {
    // if not cart, then there's nothing to remove
    if (!cart) {
      return;
    }
    sendTreatmentCartClickedRemoveFromCart({
      removedTreatments: items
        .filter((item) => item.item_type !== "product")
        .map((item) => item.treatment_product?.slug),
      removedBundle: items.some(
        (item) => item.item_type === "bundle-treatment"
      ),
      removedUpsellSkus: items
        .filter((item) => item.item_type === "product")
        .map((item) => item.ecomm_product?.sku),
      ...baseCartAnalyticsArgs,
    });
    const updatedCart = await cartService.removeItemsFromCart(
      cart.id,
      items.map((item) => item.id)
    );
    setCart(updatedCart);
  };

  const checkoutOnCart = async (consultId: string) => {
    if (!cart) {
      const errorMessage = "Error checking out: cart does not exist";
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    const purchaseType = getConsultPurchaseTypeFromCart(cart);

    // create a util for this
    const checkoutData = await cartService.createCartCheckout(
      cart.id,
      consultId,
      purchaseType
    );
    const checkoutUrl = checkoutData.checkout_url;
    return checkoutUrl;
  };

  const getIfBundleInCart = () => {
    return cart?.line_items.some(
      (item) => item.item_type === "bundle-treatment"
    );
  };

  const getIfALaCareTreatmentInCart = (treatmentSlug: string) => {
    return cart?.line_items.some(
      (item) =>
        item.item_type === "individual-treatment" &&
        item.treatment_product?.slug === treatmentSlug
    );
  };

  const getCartItemByTreatmentSlug = (treatmentSlug: string) => {
    return cart?.line_items.find(
      (item) => item.treatment_product?.slug === treatmentSlug
    );
  };

  return {
    cart,
    isLoading,
    addToCart, // function to add items to cart. Cart opens automatically after adding items
    removeFromCart, // function to remove items from cart
    checkoutOnCart, // function to checkout on cart, returns checkout URL
    getIfBundleInCart, // function to check if a bundle is in the cart
    getIfALaCareTreatmentInCart, // function to check if a specific treatment is in the cart
    getCartItemByTreatmentSlug, // function to get a cart item by treatment slug
    showCart,
    setShowCart, // function to set whether cart is shown
    latestReadyTest,
    baseCartAnalyticsArgs,
    refetchCartAndTest, // function to refetch cart and test
  };
};
