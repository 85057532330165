import { useEffect, useMemo, useState } from "react";
import { useLatestReadyTest } from "../../../hooks/test/useLatestReadyTest";
import { useProblemsSummary } from "../../../hooks/care/useProblemsSummary";
import Layout from "../../../components/layout";
import { BaseAnalyticsArgsContext } from "../../../contexts/baseAnalyticsArgsContext";
import TreatmentBundle from "../../../components/care/transparentCare/TreatmentBundle";
import ALaCare from "../../../components/care/aLaCare/ALaCare";
import CareFAQs from "../../../components/care/careFAQs";
import { Consult } from "../../../types/care";
import { CareComparison } from "../../../components/care/careComparison";
import { CareReview } from "../../../components/care/careReview";
import CareValueProps from "../../../components/care/transparentCare/CareValueProps";
import { useTransparentCareTreatments } from "../../../hooks/care/transparentCare/useTransparentCareTreatments";
import ALaCareRecommendations from "../../../components/care/aLaCare/ALaCareRecommendations";
import { getCarePageVisitedLocalStorageKey } from "../../../components/care/utils";
import ConsultCard from "../../../components/care/ConsultCard";
import AdditionalCareOptions from "../../../components/care/additionalCareOptions";
import HowItWorks from "../../../components/care/howItWorks";
import CoachingIntro from "../../../components/care/coachingIntro";
import AskEvvy from "../../../components/care/askEvvy";
import ErrorBoundary from "../../../components/common/errorBoundary";
import PastTreatmentsButtons from "../../../components/care/pastTreatmentButtons";
import Header from "../../../components/header";
import { EligibilityCountdownBanner } from "../../../components/care/eligibilityCountdownBanner";
import classNames from "classnames";
import LoadingSpinner from "../../../components/common/loadingSpinner";
import Modal from "../../../components/common/modal";
import ReferredTemplate from "../../../components/care/modalTemplates/referred";
import { sendCareViewedPage } from "../../../utils/analytics/customEventTracking";
import ReferralNote from "../../../components/care/referralNote";

interface TransparentCareLandingProps {
  consults: Consult[];
  loading: boolean;
}

// Shows user's care landing page. If no consults, lets them opt-in. If existing, redirect them.
const TransparentCareLanding = ({
  consults,
  loading = false,
}: TransparentCareLandingProps) => {
  const completedConsults = consults.filter(
    (consult) => consult.status === "CP"
  );
  // filter for all consults where is_closed is false
  const openUngatedRxConsults = consults.filter(
    (consult) => consult.type === "ungated-rx" && !consult.is_closed
  );

  const { test, isLoading: testLoading } = useLatestReadyTest();
  const { transparentCareTreatments, isLoading } = useTransparentCareTreatments(
    test?.hash
  );
  const [consult, setConsult] = useState<Consult | null>(
    // set to be last element of openConsults
    openUngatedRxConsults[openUngatedRxConsults.length - 1] || null
  );
  const [modalData, setModalData] = useState(null); // modal data for modal wrapper

  const { testSummary } = useProblemsSummary(test?.hash);

  const anyLoading = isLoading || testLoading || loading;

  const hasPastTreatments =
    consult?.status === "CP"
      ? completedConsults?.length > 1
      : completedConsults?.length > 0;
  const hasPastConsultMessages = completedConsults.some(
    (c) => c?.consultmessagethread
  );
  const latestVaginitisConsult = test?.latest_vaginitis_consult;
  const eligibleForAnyCare =
    test?.eligible_for_care || test?.eligible_for_a_la_care;
  const eligibleForALaCareOnly =
    test?.eligible_for_a_la_care && !test?.eligible_for_care;
  const PRE_REVIEW_STATUSES = ["IN", "OR", "ST"]; // consult statuses that are "pre-review"
  const CARE_PAGE_VERSION = "v2"; // const for analytics
  const isUngatedRxConsult = consult?.type === "ungated-rx";

  const carePageStatus = useMemo(() => {
    if (
      !eligibleForAnyCare ||
      !test?.latest_vaginitis_consult ||
      PRE_REVIEW_STATUSES.includes(test?.latest_vaginitis_consult?.status)
    )
      return "Start";
    else if (test?.latest_vaginitis_consult?.status === "CP") return "Ready";
    else return "In Review";
  }, [eligibleForAnyCare, test?.latest_vaginitis_consult]);

  const baseAnalyticsArgs = useMemo(() => {
    return {
      consultId: test?.latest_vaginitis_consult?.uid,
      testHash: test?.hash,
      careEligible: test?.eligible_for_care,
      eligibleTreatmentPathways:
        test?.eligible_treatment_pathways &&
        test?.eligible_treatment_pathways.length > 0
          ? test?.eligible_treatment_pathways.map((pathway) => pathway.slug)
          : [],
      carePrice: test?.eligible_for_care
        ? test?.care_pricing?.discounted_total
        : null,
      consultStatus: test?.latest_vaginitis_consult?.get_status_display,
      carePageStatus,
      pageVersion: CARE_PAGE_VERSION,
      microbiomeState: testSummary?.problems_summary.microbiome_state,
      conditions: testSummary?.problems_summary.conditions,
      symptoms: testSummary?.problems_summary.symptoms,
      aLaCareEligible: test?.eligible_for_a_la_care,
    };
  }, [
    carePageStatus,
    test?.care_pricing?.discounted_total,
    test?.eligible_for_a_la_care,
    test?.eligible_for_care,
    test?.eligible_treatment_pathways,
    test?.hash,
    test?.latest_vaginitis_consult?.get_status_display,
    test?.latest_vaginitis_consult?.uid,
    testSummary?.problems_summary.conditions,
    testSummary?.problems_summary.microbiome_state,
    testSummary?.problems_summary.symptoms,
  ]);

  // analytics trigger
  useEffect(() => {
    const recordCarePageVisited = (testHash: string) => {
      const pageViewKey = getCarePageVisitedLocalStorageKey(testHash);
      if (!localStorage.getItem(pageViewKey)) {
        localStorage.setItem(pageViewKey, "true");
      }
    };
    if (test) {
      recordCarePageVisited(test.hash);
      // also trigger care viewed page event
      sendCareViewedPage(baseAnalyticsArgs);
    }
  }, [test]);

  useEffect(() => {
    if (test && !openUngatedRxConsults.length) {
      setConsult(test.latest_vaginitis_consult || null);
    }
    // if there's no test and no open consults but there are completed consults, then set the consult to the latest completed consult
    if (!test && !openUngatedRxConsults.length && completedConsults.length) {
      setConsult(completedConsults[completedConsults.length - 1]);
    }
  }, [test, openUngatedRxConsults, completedConsults]);

  const getBaseAnalyticsEventArgs = () => {
    return {
      consultId: consult?.uid,
      testHash: test?.hash,
      testOrder: test?.test_order,
      careEligible: test?.eligible_for_care,
      carePrice: test?.eligible_for_care
        ? test?.care_pricing?.discounted_total
        : null,
      consultStatus: consult?.get_status_display,
      carePageStatus,
      pageVersion: CARE_PAGE_VERSION,
    };
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const showCareEligibleBanner =
    test?.eligible_for_care && !consult?.consult_paid;

  return (
    <BaseAnalyticsArgsContext.Provider value={{ baseAnalyticsArgs }}>
      <Layout
        title={"Care | Shop"}
        bgClass={
          (consult?.consult_paid || !eligibleForAnyCare) && !anyLoading
            ? "bg-gradient-to-r from-evvy-silverfish to-highlighter-mint"
            : "bg-evvy-cream"
        }
        full
        centered
        header={
          <Header
            isStickyHeader // special scroll behavior for care page
            noBottomPadding={showCareEligibleBanner}
            noRoundedBottom={showCareEligibleBanner}
            bottomBanner={
              showCareEligibleBanner ? (
                <EligibilityCountdownBanner test={test} goToCareOnClick />
              ) : undefined
            }
          />
        }
      >
        {anyLoading ? (
          <LoadingSpinner />
        ) : (
          <div
            className={classNames("w-full items-center", {
              "mt-[150px]": showCareEligibleBanner,
              "mt-[120px]": !showCareEligibleBanner,
            })}
          >
            {/* if consult was referred out by clinician, show referral note */}
            {consult && consult.status === "RF" && (
              <ReferralNote
                consult={consult}
                openModal={(data: any) => setModalData(data)}
              />
            )}

            {/* Regardless of vaginitis care eligibility, if they have have any paid consult, then show the consult in progress card */}
            {consult && consult.show_consult_in_progress ? (
              <div>
                <ErrorBoundary>
                  <ConsultCard
                    consult={consult}
                    test={consult.type !== "ungated-rx" ? test : undefined}
                    loading={testLoading}
                    analyticsEventArgs={getBaseAnalyticsEventArgs()}
                  />
                </ErrorBoundary>
                <ErrorBoundary>
                  {(hasPastTreatments || hasPastConsultMessages) && (
                    <PastTreatmentsButtons
                      hasPastTreatments={hasPastTreatments}
                      hasPastConsultMessages={hasPastConsultMessages}
                      baseAnalyticsEventArgs={getBaseAnalyticsEventArgs()}
                    />
                  )}
                </ErrorBoundary>
                <section className="bg-evvy-white px-7 pt-12 pb-16 sm:p-24">
                  <HowItWorks
                    careType={
                      isUngatedRxConsult ? "ungated_rx" : "transparent_care"
                    }
                  />
                </section>
                {!isUngatedRxConsult && (
                  <section className="bg-evvy-cream px-4 pt-12 pb-16 sm:p-24">
                    <AdditionalCareOptions
                      consult={consult}
                      analyticsEventArgs={getBaseAnalyticsEventArgs()}
                    />
                  </section>
                )}
                {!isUngatedRxConsult && (
                  <ErrorBoundary>
                    <section className="bg-evvy-cream">
                      <CareFAQs
                        consult={consult}
                        aLaCareEligible={test?.eligible_for_a_la_care}
                        v0BundleEligible={test?.eligible_for_care}
                        analyticsEventArgs={getBaseAnalyticsEventArgs()}
                        faqsPage={"care"}
                      />
                    </section>
                  </ErrorBoundary>
                )}
              </div>
            ) : // Is eligible for care for their latest test and has not purchased care.
            eligibleForAnyCare ? (
              <>
                <TreatmentBundle
                  transparentBundle={
                    transparentCareTreatments?.transparent_bundle
                  }
                  test={test}
                />
                {eligibleForALaCareOnly && (
                  <ALaCareRecommendations
                    recommendedALaCareTreatments={
                      transparentCareTreatments?.recommended_a_la_care_treatments
                    }
                    test={test}
                  />
                )}
                <ALaCare
                  alacareTreatments={
                    transparentCareTreatments?.a_la_care_treatments
                  }
                />
                <CareValueProps eligibleForCare={test?.eligible_for_care} />
                {!eligibleForALaCareOnly && <CareComparison />}
                {!eligibleForALaCareOnly && (
                  <section className="bg-evvy-white">
                    <CareReview />
                  </section>
                )}
                {(hasPastTreatments || hasPastConsultMessages) && (
                  <PastTreatmentsButtons
                    hasPastTreatments={hasPastTreatments}
                    hasPastConsultMessages={hasPastConsultMessages}
                    baseAnalyticsEventArgs={getBaseAnalyticsEventArgs()}
                  />
                )}
                <CareFAQs
                  consult={latestVaginitisConsult}
                  aLaCareEligible={test?.eligible_for_a_la_care}
                  v0BundleEligible={test?.eligible_for_care}
                  analyticsEventArgs={getBaseAnalyticsEventArgs()}
                  faqsPage={"care"}
                />
              </>
            ) : (
              // Ineligible for care
              <>
                {/* Clinical referred out modal; only shows once */}
                {modalData !== null && (
                  <ErrorBoundary>
                    <Modal
                      closeModal={() => handleCloseModal()}
                      widthClass={"max-w-lg"}
                      hasFocusItem
                    >
                      <ReferredTemplate
                        data={modalData}
                        handleClose={() => handleCloseModal()}
                      />
                    </Modal>
                  </ErrorBoundary>
                )}
                <CoachingIntro
                  consult={consult}
                  test={test}
                  analyticsEventArgs={getBaseAnalyticsEventArgs()}
                  completedConsults={completedConsults}
                />
                <section className="bg-evvy-cream">
                  <CareFAQs
                    consult={consult}
                    aLaCareEligible={test?.eligible_for_a_la_care}
                    v0BundleEligible={test?.eligible_for_care}
                    analyticsEventArgs={getBaseAnalyticsEventArgs()}
                    faqsPage={"care"}
                  />
                </section>
                <section className="bg-evvy-cream px-4 pt-12 pb-16 sm:p-24">
                  <AskEvvy analyticsEventArgs={getBaseAnalyticsEventArgs()} />
                </section>
              </>
            )}
          </div>
        )}
      </Layout>
    </BaseAnalyticsArgsContext.Provider>
  );
};

export default TransparentCareLanding;
